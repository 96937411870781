import { LanguageString } from "./LanguageString";
import readXlsxFile from 'read-excel-file'

let db = null;

export const loadTexts = async (doneCb)=>{

  const prepareText = (txt, id) =>{
    let cleanText = txt;
    let parts = txt?.split(id);
    if(parts[0] === "")
      parts.shift();

    cleanText = parts?.join(id);
    cleanText = cleanText.trim();

    parts = cleanText.split("\n");
    parts.shift();
    cleanText = parts.join("\n");

    console.log("Parts", id, cleanText);
    if(cleanText == "")
      return null;
    return cleanText;
  }

  let texts = {};
  fetch('_content/texts.xlsx')
  .then(response => response.blob())
  .then(blob => readXlsxFile(blob))
  .then((rows) => {
    //console.log(rows);
    for(let i in rows){
      let row = rows[i];
      let id = row[3]?.split(" ")[0];
      //console.log(id, id?.length, id?.indexOf("."));
      if(id?.length>2 && id?.length<5 && id.indexOf(".") > -1){
        let realId = id.split(".").join("-");
        //console.log(row[3], row[4], row[5]);
        if(!texts[realId]){
          texts[realId] = {de:prepareText(row[3], id), en:prepareText(row[4], id), nl:prepareText(row[5], id)};
          if(!texts[realId].de && !texts[realId].en && !texts[realId].nl){

            delete texts[realId];
          }
        }
        else{
          console.error("Duplicate ID", id, "In row", i);
        }
      }
    }
    doneCb && doneCb(texts)
  })
}


export const LoadDeafTextsToDb = (db, doneHandler)=>{
  loadTexts((texts)=>{
    console.log("Loaded texts", texts, db);


    for(let i in texts){
      let txt = texts[i];
      let entry = db.find(i);
      entry.deafText = new LanguageString(txt);
   //   console.log(i,entry.deafText , entry);
    }
    doneHandler && doneHandler();
  });
}

export function ParseDB(raw){
  let db = new DB(raw);

  db.timeout = (raw.timeout || 10) * 1000;
  db.lang = {};
  for(let i in raw.lang){
    db.lang[i] = new LanguageString(raw.lang[i]);
  }



  return db;
}

let inc=0;

const getNextId = () =>{
  inc++;
  return inc;
}

class DB{
  constructor(raw){
    this.idle = {title:new LanguageString(raw.idle.title), text:new LanguageString(raw.idle.text)};
    this.intro = {title:new LanguageString(raw.intro.title), video:new LanguageString(raw.intro.video)};
    this.imprint = {title:new LanguageString(raw.imprint.title), image:new LanguageString(raw.imprint.image)};

    this.entries = [];
    for(let i in raw.entries){
      this.entries.push(new Entry(raw.entries[i]));
    }


    console.log("Parsed DB", this);
  }

  find(id){
    for(let i in this.entries){
      if(this.entries[i].id === id)
        return this.entries[i];
      for(let j in this.entries[i].subentries){
        if(this.entries[i].subentries[j].id === id)
          return this.entries[i].subentries[j];
      }
    }
    return null;
  }
}

class Entry{
  constructor(raw){
    this.title = new LanguageString(raw.title);
    this.audio = new LanguageString(raw.audio);
    this.deafText = null;
    this.intro = new LanguageString(raw.intro);
    this.id = raw.id||getNextId();

    this.subentries = [];
    for(let i in raw.subentries){
      this.subentries.push(new SubEntry(raw.subentries[i]));
    }
  }
}
class SubEntry{
  constructor(raw){
    this.title = new LanguageString(raw.title);

    this.id = raw.id||getNextId();
    this.type = raw.type;
    this.position = {x:raw.position.x||1, y:raw.position.y||1};
    this.audio = new LanguageString(raw.audio);
    this.media = [];
    for(let i in raw.media){
      this.media.push(new LanguageString(raw.media[i]));
    }
  }
}


