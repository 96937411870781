import { useDispatch, useSelector } from 'react-redux';
import classes from './DeafText.module.css';
import { idleOff, idleOn, setActiveContent, setApp, setIntroEnded, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect, useRef, useState } from 'react';
import { GetContentPath } from '../../App';

export default function DeafText() {
  const ref = useRef(null);
  const app = useSelector((state) => state.app?.app);
  const mainEntryId = useSelector((state) => state.app?.activeEntry);
  const deaf = useSelector((state) => state.app?.deaf);
  const entryId = useSelector((state) => state.app?.activeContent);
  const introEnded = useSelector((state) => state.app?.introEnded);
  //const [entry, setEntry] = useState(null);
  useEffect(() => {
    ref.current?.classList.add(classes.lastEntry);
    ref.current?.classList.remove(classes.lastDeaf);
  },[entryId]);

  useEffect(() => {
    ref.current?.classList.remove(classes.lastEntry);
    ref.current?.classList.add(classes.lastDeaf);
  },[deaf]);

 /* let entry = window.DB?.find(entryId);
  if(entry?.deafText){
    console.log("deafText", window.lastMediumPos, entry.id);
  }else{
    console.log("no deafText", entry);
  }*/
    let id = entryId;
    let isMain = false;
    if(!id && mainEntryId){
      id = mainEntryId;
      isMain = true;
    }
    let entry = window.DB?.find(id);
    //console.log("entry", mainEntryId,entryId, entry);
    if(entry?.deafText){
      console.log("deafText", window.lastMediumPos, isMain, entry?.id);
    }else{
      console.log("no deafText", entry);
      return(null);
    }


    if(!deaf /*|| (isMain && introEnded)*/)
      return(null);

  let classs = "";

  if(isMain){
    console.log(mainEntryId)
    classs = classes["main_"+mainEntryId];
  }
  console.log(id);
  return (
    <div ref={ref} className={classs+ " " +classes.root + " " + (window.lastMediumPos==="left"?classes.right:classes.left) + " " + (isMain?classes.main:'') + " _"+id} key={entry?.id}>
      <div className={classes.deafText}>
        <h1>{entry?.title?.Get()}</h1>
        {entry?.deafText?.Get()}
      </div>
    </div>
  );
}

