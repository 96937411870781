import { useDispatch, useSelector } from "react-redux";
import classes from "./NaviationMain.module.css";
import {
    idleOff,
    idleOn,
    setActiveEntry,
    setApp,
    setDeaf,
    setLanguage,
    toggleLanguage,
} from "./../../_redux/appSlice";
import { useEffect, useState } from "react";
import NavigationSub from "../NavigationSub/NavigationSub";

export default function NaviationMain() {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app?.app);
    const idleMode = useSelector((state) => state.app?.idleMode);
    const deaf = useSelector((state) => state.app?.deaf);
    const activeEntryId = useSelector((state) => state.app?.activeEntry);

    const language = useSelector((state) => state.app?.language);
    const introEnded = useSelector((state) => state.app?.introEnded);

    if (window.DB == null) return <></>;
    return (
        <div className={classes.root}>
            <div
                className={
                    classes.entries +
                    " " +
                    (activeEntryId ? classes.activated : "") +
                    " " +
                    (introEnded ? classes.introEnded : "")
                }
            >
                {window.DB?.entries?.map((entry, index) => (
                    <div
                        key={index}
                        id={"navEntry_" + entry.id}
                        style={{ "--x": index / window.DB?.entries.length }}
                        className={
                            classes.mainEntry+" "+
                            (activeEntryId === entry.id ? classes.active : "")
                        }
                    >
                        <div className={classes.label}>{entry.title.Get()}</div>
                        <div
                            className={classes.icon + " icon"}
                            style={{
                                "--bg": `url(/assets/nav_entry_active.png)`,
                            }}
                            onClick={() => dispatch(setActiveEntry(entry.id))}
                        >
                             <div className={classes.circle}>
                                <div className={classes.char}>{index + 1}</div>
                            </div>

                        </div>
                        <NavigationSub
                            entries={entry?.subentries}
                            className={classes.subEntries}
                        ></NavigationSub>
                    </div>
                ))}
                <div
                    className={
                        classes.intro +
                        " " +
                        classes.small +
                        " " +
                        (activeEntryId == null ? classes.active : "")
                    }
                    style={{ "--x": 0.8 / window.DB?.entries.length }}
                >
                    <div className={classes.label}>
                        {window.DB?.intro.title.Get()}
                    </div>
                    <div
                        className={classes.icon + " icon"}
                        style={{ "--bg": `url(/assets/nav_entry_active.png)` }}
                        onClick={() => dispatch(setActiveEntry(null))}
                    >
                        <div></div>
                    </div>
                </div>

                <div
                    className={
                        classes.imprint +
                        " " +
                        (activeEntryId == "imprint" ? classes.active : "")
                    }
                    onClick={() => dispatch(setActiveEntry("imprint"))}
                >
                    <div className={classes.label}>
                        {window.DB?.imprint.title.Get()}
                    </div>
                    <div
                        className={classes.imprintIcon}
                    >
                        <div>§</div>
                    </div>
                </div>
            </div>

            <div className={classes.languages}>
                <div
                    className={
                        classes.lang +
                        " " +
                        (language == "de" ? classes.active : "")
                    }
                    onClick={() => dispatch(setLanguage("de"))}
                >
                    <div className={classes.langIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1000"
                            height="600"
                            viewBox="0 0 5 3"
                        >
                            <rect
                                id="black_stripe"
                                width="5"
                                height="3"
                                y="0"
                                x="0"
                                fill="#000"
                            />
                            <rect
                                id="red_stripe"
                                width="5"
                                height="2"
                                y="1"
                                x="0"
                                fill="#D00"
                            />
                            <rect
                                id="gold_stripe"
                                width="5"
                                height="1"
                                y="2"
                                x="0"
                                fill="#FFCE00"
                            />
                        </svg>
                    </div>
                    Deutsch
                </div>
                <div
                    className={
                        classes.lang +
                        " " +
                        (language == "en" ? classes.active : "")
                    }
                    onClick={() => dispatch(setLanguage("en"))}
                >
                    <div className={classes.langIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30" width="1000" height="600">
                        <clipPath id="t">
                          <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/>
                        </clipPath>
                        <path d="M0,0v30h50v-30z" fill="#012169"/>
                        <path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/>
                        <path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
                        <path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/>
                      </svg>
                    </div>
                    English
                </div>
                <div
                    className={
                        classes.lang +
                        " " +
                        (language == "nl" ? classes.active : "")
                    }
                    onClick={() => dispatch(setLanguage("nl"))}
                >
                    <div className={classes.langIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="900" height="600" viewBox="0 0 9 6">
                        <rect fill="#21468B"	width="9" height="6"/>
                        <rect fill="#FFF" width="9" height="4"/>
                        <rect fill="#AE1C28"	width="9" height="2"/>
                      </svg>
                    </div>
                    Nederlands
                </div>

                {/*
                <div
                    className={
                        classes.lang +
                        " " +
                        (deaf ? classes.active : "")
                    }
                    onClick={() => dispatch(setDeaf(!deaf))}
                >
                    <div className={classes.langIcon + " " + classes.deaf}>
                      <img src={`/assets/icon_${deaf?"deaf":"deaf_no"}.png`} />

                    </div>
                </div>
 */}
                <div
                    className={
                        classes.lang +
                        " " +
                        (deaf ? classes.active : "")
                    }
                    onClick={() => dispatch(setDeaf(true))}
                >
                    <div className={classes.langIcon + " " + classes.deaf}>
                      <img src={`/assets/icon_${"deaf_no"}.png`} />
                    </div>
                </div>
                <div
                    className={
                        classes.lang +
                        " " +
                        (!deaf ? classes.active : "")
                    }
                    onClick={() => dispatch(setDeaf(false))}
                >
                    <div className={classes.langIcon + " " + classes.deaf}>
                      <img src={`/assets/icon_${"deaf"}.png`} />
                    </div>

                </div>


            </div>
        </div>
    );
}
