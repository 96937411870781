import { useDispatch, useSelector } from 'react-redux';
import classes from './NavigationSub.module.css';
import { idleOff, idleOn, setActiveContent, setApp, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect } from 'react';

export default function NavigationSub({entries, className}) {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const idleMode = useSelector((state) => state.app?.idleMode);
  const language = useSelector((state) => state.app?.language);
  const activeSubEntryId = useSelector((state) => state.app?.activeContent);


  const debug = document.body.classList.contains("debug");
  useEffect(() => {

   },[]);


  if(window.DB == null)
   return <></>;
  return (
    <div className={classes.root+" "+ className}>
      <div className={classes.entries}>
        {entries?.map((entry, index) =>
          <div key={index} style={{'--x':entry?.position?.x, '--y':entry?.position?.y}} className={entry.type + " "+ (entry.id === activeSubEntryId?classes.active:'')}>
            <div className={classes.circle} onClick={()=>dispatch(setActiveContent(entry.id))}>
              <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/icon_${entry.type}.png)`}}>
                <div>{index+1}</div>
              </div>
            </div>
            <div className={classes.label}>
              {false && debug && (entry.id+" ")}{entry.title.Get()}
            </div>

          </div>
          )}


      </div>

    </div>
  );
}

