import { useDispatch, useSelector } from 'react-redux';
import classes from './ContentPlayer.module.css';
import { idleOff, idleOn, setActiveContent, setApp, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect, useRef, useState } from 'react';
import { GetContentPath } from '../../App';

let to;
export default function ContentPlayer() {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const [medium, setMedium] = useState(null);
  const entryId = useSelector((state) => state.app?.activeContent);
  const mainEntryId = useSelector((state) => state.app?.activeEntry);
  const language = useSelector((state) => state.app?.language);
  const ref = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if(audioRef.current?.pause)
      audioRef.current.pause();
  },[]);
  useEffect(() => {
    let entry = window.DB?.find(entryId);


    clearTimeout(to);
    setMedium(entry?.media[0]);
    setTimeout(()=>ref.current?.classList.remove(classes.hidden), 100);
   },[entryId, setMedium]);

   useEffect(() => {
    let entry = window.DB?.find(entryId);
    clearTimeout(to);

    if(entry?.media.length>1 && !medium?.Get().endsWith(".mp4") &&  !medium?.Get().endsWith(".webm"))
    {
      to = setTimeout(()=>{
        const mediumIndex = entry?.media.indexOf(medium);
        if(mediumIndex<entry?.media.length-1)
          setMedium(entry?.media[mediumIndex+1]);
        else
          ;//setMedium(entry?.media[0]);
      }, 5000);
    }
   },[medium, setMedium]);

   const playNext = ()=>{
    console.log("playNext", entry?.media.length, entry?.media.indexOf(medium));

      if(entry?.media.indexOf(medium)<entry?.media.length-1){
        setMedium(entry?.media[entry?.media.indexOf(medium)+1]);
      }else{
        console.log("lastmeida video")
        dispatch(setActiveContent(null))
        //loop
        //setMedium(entry?.media[0]);
      }
    }


   const hasAudio = (entry) =>{
    if(language != "de")
      return true;

    return true;
   }

   const mediumSizeLoaded = (target, width, height) =>{
   // console.log("mediumSizeLoaded", width, height);

    const mediumWidth = 428;
    const ratio = width/mediumWidth;
    ref.current.style.height = (height/ratio+70)+"px";
    target.classList.add(classes.loaded)
   }


   const isVideoMuted = (src) =>{
    if(language != "de")
      return false;
    if(src.indexOf("3-1.")>-1)
      return false;
    if(src.indexOf("4-1.")>-1)
      return false;
    if(src.indexOf("7-2-b.")>-1)
      return false;
    return true;
   }

  let entry = window.DB?.find(entryId);
  let mainEntry = window.DB?.find(mainEntryId);


  let position = "left";
  for(let i in mainEntry?.subentries){
    let mainEntrySub = mainEntry?.subentries[i];
    if(mainEntrySub.position?.x < 3)
      position = "right";
  }

  if(entry == null)
   return <></>;

   if(medium?.Get())
    console.log(medium?.Get())


   window.lastMediumPos = position;
  return (
    <div className={classes.root + " "+classes[position] + " " + classes.hidden} ref={ref} key={entryId}>
        <div className={classes.content}>
          {medium &&
            (medium.Get().endsWith(".mp4") || medium.Get().endsWith(".webm")?
              <video controls={document.body.classList.contains("debug")} autoPlay muted={isVideoMuted(medium.Get())} playsInline className={classes.video} src={GetContentPath( medium.Get())} onEnded={()=>playNext()} onLoadedMetadata={(e)=>mediumSizeLoaded(e.target, e.target.videoWidth, e.target.videoHeight)}></video>
            :
              <img key={ medium?.Get()} className={classes.image} src={GetContentPath( medium?.Get())} onLoad={(e)=>mediumSizeLoaded(e.target, e.target.naturalWidth, e.target.naturalHeight)}></img>
            )
          }
          {entry?.audio && hasAudio(entry) && <audio autoPlay playsInline className={classes.video} src={GetContentPath( entry?.audio.Get())} ref={audioRef}></audio>}
        </div>
        <div className={classes.footer}>
          <div className={classes.title +" "+entry.type}>
            <div className={classes.circle}>
              <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/icon_${entry.type}.png)`}}>
                <div></div>
              </div>
            </div>
            <div className={classes.label}>
              {entry.title.Get()}
            </div>

          </div>
          <div className={classes.closeBtn} onClick={()=>dispatch(setActiveContent(null))}
          >
            <div className={classes.circle}>
              <div className={"icon"} style={{'--bg':'url(/assets/icon_close.png)'}}></div>
            </div>
            {window.DB.lang.close.Get()}
          </div>
        </div>

    </div>
  );
}

